.App {
  background-color: #081b26;
  color: white;
  
  -webkit-user-drag: none;
  
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.scrollable::-webkit-scrollbar {
  display: none;
}

.scrollable {
  overflow: "auto";
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
