.Col {
  padding: 5px;
}

.Nav {
  max-width: 700px;
  max-height: 700px;
  padding: 20px;
}

img.nav.return {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 3px;
  left: 3px;
}

img.nav {
  border-radius: 40%;
}

img.nav.inactive {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

img.nav.active:hover {
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
}
