.card {
  color: #000000;
  text-align: center;
  padding: 5px;
}

.glitch {
  display: inline-block;
  animation: glitch 1s linear infinite;
}

@keyframes glitch {
  2%,
  64% {
    transform: translate(.5px, 0) skew(0deg);
  }
  4%,
  60% {
    transform: translate(-.5px, 0) skew(0deg);
  }
  62% {
    transform: translate(0, 0) skew(10deg);
  }
}

.glitch:before,
.glitch:after{
  content: attr(title);
  position: absolute;
  left: 0;
}

.glitch:before {
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0% 0%, 100% 0%, 100% 33%, 0% 33%);
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 33%, 0% 33%);
}

@keyframes glitchTop {
  2%,
  64% {
    transform: translate(.5px, -.5px);
  }
  4%,
  60% {
    transform: translate(-.5px, .5px);
  }
  62% {
    transform: translate(2px, -1px) skew(-8deg);
  }
}

.glitch:after {
  animation: glitchBottom 1.5s linear infinite;
  clip-path: polygon(0% 67%, 100% 67%, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0% 67%, 100% 67%, 100% 100%, 0% 100%);
}

@keyframes glitchBottom {
  2%,
  64% {
    transform: translate(-.5px, 0);
  }
  4%,
  60% {
    transform: translate(-.5px, 0);
  }
  62% {
    transform: translate(-3px, 2px) skew(11deg);
  }
}


